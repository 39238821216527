<template>
  <div class="menu-preview-wrapper">
    <div class="menu-preview-content" v-if="!loading && !error">
      <!-- <PreviewNavbar
        :title="cafe.name"
        :showArrowBack="menu_view == 'details'"
        @go-back="goBack"
        /> -->
      <Navbar
        :cafe="cafe"
        :target="target"
        :menu="currentMenu"
        :show_status="true"
        :is_preview="true"
        ref="navbar"
        :is_locked="false"
        :showArrowBack="canShowArrowBack"
        @go-back="navigateBack"
        v-if="isMobile"
      />
      <DesktopNavbar
        :hide_menu="false"
        :menu="currentMenu"
        :view="'menu'"
        :hideArrowBack="false"
        :hideCart="true"
        :cafe="cafe"
        menu_type="preorder"
        variant="cart"
        v-else
      />


      <OrderMenu
        :cafe="cafe"
        :menu="currentMenu"
        @menuViewChange="(v) => (currentMenuView = v)"
        :target="target"
        :tagged_menus="menus"
        :menu_index="currentMenuIndex"
        @switchMenu="switchToMenu"
        :disable_cart="true"
        :cart="[]"
        ref="orderMenu"
      />
    </div>

    <LoadError :error="error" v-if="error" />

    <OrderLoader v-if="loading" />
  </div>
</template>

<script>
import OrderLoader from "@/components/OrderLoader.vue";
import LoadError from "@/components/LoadError.vue";
import OrderMenu from "@/components/OrderMenu.vue";
import PreviewNavbar from "@/components/navbar/PreviewNavbar.vue";
import Navbar from "@/components/Navbar.vue";
import AnalyticsAPI from "@/api/analytics";
import DesktopNavbar from "@/components/desktop/NavbarDesktop.vue"

import MenuAPI from "@/api/menu";

import ThemeService from '@/services/theme/service'

import Polyglote from "@/services/polyglote";

import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      error: null,

      cafe: {},
      menu: {},
      target: {
        cafe_id: -1,
        preorder: true,
        preview: true,
      },
      cart: [],
      tagged_menus: [],
      menu_view: "categories",
      currentMenuIndex: 0,
      currentMenuView: "categories",
    };
  },
  methods: {
    fetchMenu() {
      MenuAPI.getPreview(this.$route.params.id, this.$route.query.menu_id || undefined)
        .then((data) => {
          this.cafe = data.cafe;
          this.menus = data.menus;

          if (data.currency) {
            this.$store.commit("currency/setCurrent", data.currency);
          }

          this.$store.commit('currency/setCanRoundPrices', this.cafe.round_menu_prices);

          this.loading = false;

          this.$store.commit("menu/setMenus", this.menus);
          this.$store.dispatch("menu/buildAllTranslations", [
            this.$i18n.locale,
            ...Polyglote.getAllLocales(),
          ]);

          if (this.cafe._id == 126) {
            this.$i18n.locale = 'rus';
          }

          AnalyticsAPI.registerView({
            cafe_id: this.cafe._id,
            mode: 'preview',
            client_id: null,
            platform: "web",
            from_marketplace: false,
            referer: document.referrer,
          }).catch((error) => {
            console.error(`Analytics registerView() failed: ${error}`);
          });

          if (this.$route.query.category_ids) {
            const ids = this.$route.query.category_ids.split(',');

            if (this.menus[0]) {
              this.menus[0].categories = this.menus[0].categories.filter(c => ids.includes(c._id));
            }
          }

          if (this.$route.query.menu_id == '626e372b2bc3f60e72c63b07') {
            this.cafe.name = 'Mota SPA';
            this.cafe.image = 'https://cdn.qrwaiter.com.ua/mota-spa-logo-02.jpg';
          }

          if (this.cafe.ui_settings && this.cafe.ui_settings.locale_override) {
            this.$i18n.locale = this.cafe.ui_settings.locale_override;
          }

          if (this.cafe.ui_settings && this.cafe.ui_settings.theme_override) {
            ThemeService.setGlobalOverride(this.cafe.ui_settings.theme_override);
          }
        })
        .catch((error) => {
          this.error = error;
          this.loading = false;
        });
    },
    goBack() {
      this.$refs.orderMenu.onBackArrowPressed();
    },
    switchToMenu(index) {
      this.currentMenuIndex = index;


    },
    navigateBack() {
      if (this.currentMenuView == "categories") {
        this.$router.push({
          name: "choose_mode",
          params: {
            code: this.$route.params.id,
          },
          query: {
            returning: "1",
          },
        });
      } else {
        this.$refs.orderMenu.onBackArrowPressed();
      }
    },
  },
  components: {
    OrderLoader,
    LoadError,
    OrderMenu,
    PreviewNavbar,
    Navbar,
    DesktopNavbar
  },
  computed: {
    currentMenu() {
      return this.menus[this.currentMenuIndex];
    },
    canShowArrowBack() {
      const id = this.$route.params.id;
      if (this.currentMenuView != "categories") return true;
      if (this.$route.query.menu_id) return false;
      return !['yeahnot', 'yizh_dvizh'].includes(id);
    }
  },
  mounted() {
    window.document.title = "Menu Preview - QRWaiter";
    this.fetchMenu();
  },
};
</script>

<style scoped>
</style>
