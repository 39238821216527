<template>
  <div class="black-navbar">
    <div class="navbar-controls">
      <img
        src="../../assets/arrow-back.svg"
        class="arrow-back"
        @click="$emit('go-back')"
        v-if="showArrowBack"
      />
      <div class="navbar-info">
        <h3 class="navbar-title">{{ title }}</h3>
        <span class="navbar-subtitle">PREVIEW</span>
      </div>
    </div>

    <img src="../../assets/white-logo.png" class="navbar-logo" />
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    showArrowBack: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.black-navbar {
  display: flex;
  z-index: 2;
  position: fixed;
  width: 100vw;
  background: #1d1d1b;
  top: 0;
  left: 0;

  color: #ededed;

  padding-top: 1.4vh;
  padding-bottom: 0.7vh;
  padding-left: 2.5vh;
  padding-right: 2.5vh;

  justify-content: space-between;
}

.navbar-info {
  display: block;
  margin-left: 2vw;
}

.navbar-title {
  font-size: 4.3vw;
  font-weight: 400;
  margin: 0;
  line-height: 2vh;
}

.navbar-subtitle {
  margin: 0;
  color: #a4a3a1;
  font-size: 3.5vw;
  font-weight: 500;
}

.navbar-logo {
  height: 4.2vh;
  width: auto;
}

.navbar-controls {
  display: flex;
  align-items: center;
  align-self: center;
}

img.arrow-back {
  /* margin-right: 1.2vh; */
  /* margin-left: -0.8vh; */
  height: 3.2vh;
  width: auto;
  margin-bottom: 0.3vh;
}
</style>