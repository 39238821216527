import BaseAPI from '../base'

function getPreview(id, menu_id) {
    return BaseAPI.sendPost(`/menu/preview/${id}`, {
        menu_id
    });
}

export default {
    getPreview
}